import Swiper, { Navigation, Pagination } from 'swiper';
import $ from 'jquery';

const apartmentsSLiderPlayer = () => {
  const init = () => {
    slider();
  }

  const slider = () => {
    const el = {
      swiper: '.js-ApartmentsSlider-swiper'
    }

    if ($(el.swiper).length === 0) return;

    new Swiper(el.swiper, {
      // Optional parameters
      modules: [Navigation, Pagination],
      direction: 'horizontal',
      // loop: true,
      speed: 1000,
      centeredSlides: true,
      slidesPerView: "auto",
      spaceBetween: 30,
      clickable: true,
      autoHeight: true,

      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        enabled: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        // enabled: false,
      },
      breakpoints: {
        320: {
          slidesPerView: "1",
          pagination: {
            enabled: true,
          },
        },
        600: {
          slidesPerView: "1.8",
          pagination: {
            enabled: true,
          },
        },
        1025: {
          slidesPerView: "1",
          pagination: {
            enabled: false,
          },
        },
      },
    });

  }

  init()
}


try {
  apartmentsSLiderPlayer();
} catch (e) {
  console.error('slider Apartments : ', e);
}