import $ from "jquery";

(() => {
  const el = {
    langauge_change_element: ".gt_selected",
  };

  const changed_language = () => {
    $(document).on("click", ".gt_option a", function () {
      const lang = $(this).data("gtLang");
      change_booking_form_by_language(lang);
    });

    const change_booking_form_by_language = (lang) => {
      $.ajax({
        method: "POST",
        // eslint-disable-next-line no-undef
        url: parameters.url_admin_ajax,
        data: {
          action: parameters.change_booking_form_language.action,
          nonce: parameters.change_booking_form_language.nonce,
          language: lang,
        },
      })
        .done((res) => {
          if (res) {
            location.reload();
          }
        })
        .fail((err) => {
          console.log(err);
        });
    };
  };

  try {
    changed_language();
  } catch (error) {
    console.log(error);
  }
})();
