import Swiper, { Navigation, Pagination } from 'swiper';
import $ from 'jquery';

const omgevingPlayer = () => {
  const init = () => {
    slider();
  }

  const slider = () => {
    const el = {
      swiper: '.js-OmgevingSlider-swiper'
    }

    if ($(el.swiper).length === 0) return;

    new Swiper(el.swiper, {
      // Optional parameters
      modules: [Navigation, Pagination],
      direction: 'horizontal',
      // loop: true,
      speed: 1000,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      // slidesPerView: 4,
      // spaceBetween: 30,
      clickable: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        480: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        700: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 30,
        }
      },
    });

  }

  init()
}


try {
  omgevingPlayer();
} catch (e) {
  console.error('slider Omgeving : ', e);
}