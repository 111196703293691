/* eslint-disable no-undef */
import $ from "jquery";

(() => {
  $(window).on("load", () => {
    // for (const [key, value] of searchParams.entries()) {
    //   switch (key) {
    //     case "check_in":
    //       $("input.DOPBSPCalendar-check-in1").val(value);
    //       break;
    //     case "check_out":
    //       $("input.DOPBSPCalendar-check-out1").val(value);
    //       break;
    //     case "total":
    //       setTimeout(() => {
    //         //   document.querySelector("input[id^='DOPBSPCalendar-no']").value =
    //         //     value;

    //         $("div[id^='DOPBSPCalendar-no-items-wrapper']")
    //           .find("input[id^='DOPBSPCalendar-no']")
    //           .val(value);

    //         $("div[id^='DOPBSPCalendar-no-items-wrapper']")
    //           .find(".dopselect-selection")
    //           .text(value);

    //         $("div[id^='DOPBSPCalendar-no-items-wrapper']")
    //           .find(`ul li`)
    //           .each((index, el) => {
    //             const $title = $(el).attr("title");

    //             const click = new Event("click");
    //             $title != value
    //               ? $(el).removeClass("dopselect-selected")
    //               : el.dispatchEvent(click);
    //           });
    //       }, 1000);

    //       break;
    //     default:
    //       break;
    //   }
    // }

    const init = () => {
      init_append_search_params();
      reload_after_submit_reservation();
    };

    const reload_after_submit_reservation = () => {
      $(document).on("click", "input[id^='DOPBSPCalendar-submit']", (e) => {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();

        // location.reload();

        // Create a MutationObserver instance
        const observer = new MutationObserver(function (
          mutationsList,
          observer
        ) {
          // Check if any mutations involve style changes
          for (var mutation of mutationsList) {
            if (
              mutation.type === "attributes" &&
              mutation.attributeName === "style"
            ) {
              display_pop_up();
              // Reload the page
              setTimeout(() => {
                location.reload();
              }, 1500);

              // Stop observing changes
              observer.disconnect();
              break;
            }
          }
        });

        const notifMessage = document.querySelector(
          "div[id^='DOPBSPCalendar-info-message']"
        );
        observer.observe(notifMessage, {
          attributes: true,
          attributeFilter: ["style"],
        });
      });
    };

    // append data from url after dopbsp plugin done load its elements
    const init_append_search_params = () => {
      const dopbspLoader = document.getElementById("dopbsp-calendar-custom");
      const removeElementObserver = new MutationObserver((mutationsList) => {
        // for (let index = 0; index < mutationsList.length; index++) {
        //   if (
        //     mutationsList[index].removedNodes &&
        //     mutationsList[index].removedNodes.length > 0
        //   ) {
        //     // Element(s) removed from the DOM
        //     const removedElements = Array.from(
        //       mutationsList[index].removedNodes
        //     );

        //     for (let j = 0; j < removedElements.length; j++) {
        //       if (removedElements[j].classList) {
        //         console.log(removedElements);
        //         if (removedElements[j].classList.contains("dopbsp-loader")) {
        //           append_search_params();
        //         }
        //       }
        //     }

        //     removeElementObserver.disconnect();
        //   }
        // }

        for (let mutation of mutationsList) {
          const removedNodes = Array.from(mutation.removedNodes);
          const removedElements = removedNodes.filter(
            (node) => node instanceof Element
          );

          if (removedElements.length > 0) {
            for (let removedElement of removedElements) {
              if (removedElement.classList.contains("dopbsp-loader")) {
                append_search_params();
              }
            }
          }
        }
      });

      removeElementObserver.observe(dopbspLoader, {
        childList: true,
        subtree: true,
      });
    };

    // get data from url
    const append_search_params = () => {
      const searchParams = new URLSearchParams(window.location.search);
      for (const [key, value] of searchParams.entries()) {
        console.log(value);
        switch (key) {
          case "check_in":
            $("input.DOPBSPCalendar-check-in1").val(value);
            break;
          case "check_out":
            $("input.DOPBSPCalendar-check-out1").val(value);
            break;
          case "total":
            $("div[id^='DOPBSPCalendar-no-items-wrapper']")
              .find("input[id^='DOPBSPCalendar-no']")
              .val(value);

            $("div[id^='DOPBSPCalendar-no-items-wrapper']")
              .find(".dopselect-selection")
              .text(value);

            $("div[id^='DOPBSPCalendar-no-items-wrapper']")
              .find(`ul li`)
              .each((index, el) => {
                const $title = $(el).attr("title");

                const click = new Event("click");
                $title != value
                  ? $(el).removeClass("dopselect-selected")
                  : $(el).trigger("click");
              });
            // }, 1000);

            break;
          default:
            break;
        }
      }
    };

    const display_pop_up = () => {
      elementorProFrontend.modules.popup.showPopup({ id: 1143 });
    };

    init();
  });
})();
