import $ from "jquery";

const booking = () => {
  const init = () => {
    styling();
    // datePicker();
  };

  const styling = () => {
    $(() => {
      const el = {
        page: ".single-vakantiewoningen",
        inputDateWrapper:
          ".mi-Booking .dopbsp-row1 > .dopbsp-module .dopbsp-input-wrapper.DOPBSPCalendar-left",
        inputDateCheckIn:
          ".mi-Booking .dopbsp-row1 > .dopbsp-module .dopbsp-input-wrapper.DOPBSPCalendar-left:first-child",
        inputDateCheckOut:
          ".mi-Booking .dopbsp-row1 > .dopbsp-module .dopbsp-input-wrapper.DOPBSPCalendar-left:nth-child(2)",
        labelNumberOfPerson:
          ".mi-Booking .dopbsp-row1 > .dopbsp-module .dopbsp-input-wrapper label",
        sectionTitleForm: ".mi-Booking .dopbsp-row3 h4",
        labelRadio: ".mi-Booking .dopbsp-row4 .dopbsp-for-radio",
        labelStateSelect: ".mi-Booking .dopbsp-row4 .dopbsp-message",
        input1:
          ".mi-Booking .dopbsp-column6 .dopbsp-row3 > .dopbsp-module .dopbsp-input-wrapper:first-of-type > input[type='text']",
        input2:
          ".mi-Booking .dopbsp-column6 .dopbsp-row3 > .dopbsp-module .dopbsp-input-wrapper:nth-of-type(2) > input[type='text']",
        input3:
          ".mi-Booking .dopbsp-column6 .dopbsp-row3 > .dopbsp-module .dopbsp-input-wrapper:nth-of-type(3) > input[type='text']",
        button:
          ".mi-Booking .dopbsp-row4 .dopbsp-input-wrapper input[type='submit']",
      };



      if ($(el.page).length === 0) return;

      const changeText = (
        radio,
        sectionTitle,
        labelPerson,
        inputCheckin,
        inputCheckout,
        state,
        input1,
        input2,
        input3,
        button
      ) => {
        $(el.labelRadio).text(radio);
        $(el.sectionTitleForm).text(sectionTitle);
        $(el.labelNumberOfPerson).text(labelPerson);
        $(el.inputDateCheckIn).children("label").text(inputCheckin);
        $(el.inputDateCheckOut).children("label").text(inputCheckout);
        $(el.labelStateSelect).text(state);
        $(el.input1).attr("placeholder", input1);
        $(el.input2).attr("placeholder", input2);
        $(el.input3).attr("placeholder", input3);
        $(el.button).attr("value", button);
      };

      const listeningHTMLChange = () => {
        const getField = setInterval(() => {
          // eslint-disable-next-line no-console
          console.log($("html").attr("lang"));
          if ($(el.input1).length > 0 && $("html").attr("lang") === "nl-NL") {
            // eslint-disable-next-line no-console
            console.log(
              `"lang" property changed. New value: ${el.input1}`
            );
            changeText(
              "Betaal bij aankomst (moet worden goedgekeurd)",
              "Persoonlijke gegevens",
              "Aantal personen",
              "Incheckdatum",
              "Uitcheckdatum",
              "Selecteer de dagen uit de kalender.",
              "Uw volledige naam",
              "Telefoonnummer",
              "E-mailadres",
              "Reserveer"
            );
            clearInterval(getField);
          }
        }, 100);

        // Create a new MutationObserver instance
        const observer = new MutationObserver((mutationsList) => {
          for (const mutation of mutationsList) {
            if (
              mutation.type === "attributes" &&
              mutation.attributeName === "lang"
            ) {
              const newLangValue = $(mutation.target).attr("lang");
              // eslint-disable-next-line no-console
              console.log(
                `"lang" property changed. New value: ${newLangValue}`
              );

              // Perform your desired actions here
              if (newLangValue === "auto" || newLangValue === "nl" || newLangValue === "nl-NL") {
                const getField = setInterval(() => {
                  if ($(el.input1).length > 0) {
                    changeText(
                      "Betaal bij aankomst (moet worden goedgekeurd)",
                      "Persoonlijke gegevens",
                      "Aantal personen",
                      "Incheckdatum",
                      "Uitcheckdatum",
                      "Selecteer de dagen uit de kalender.",
                      "Uw volledige naam",
                      "Telefoonnummer",
                      "E-mailadres",
                      "Reserveer"
                    );
                    clearInterval(getField);
                  }
                }, 100);
              } else if (newLangValue === "en") {
                const getField = setInterval(() => {
                  if ($(el.input1).length > 0) {
                    changeText(
                      "Pay on arrival (must be approved)",
                      "Personal information",
                      "Number of persons",
                      "Check-in date",
                      "Check-out date",
                      "Please select the days from calendar.",
                      "Full Name",
                      "Phone Number",
                      "E-mail Address",
                      "Reserve"
                    );
                    clearInterval(getField);
                  }
                }, 100);
              } else if (newLangValue === "de") {
                const getField = setInterval(() => {
                  if ($(el.input1).length > 0) {
                    changeText(
                      "Bezahlung bei Ankunft (muss genehmigt werden)",
                      "Persönliche Angaben",
                      "Personenzahl",
                      "Check-in Datum",
                      "Check-out Datum",
                      "Bitte wählen Sie die Tage aus dem Kalender aus.",
                      "Ihr vollständiger Name",
                      "Telefonnummer",
                      "E-Mail-Addresse",
                      "Reservieren"
                    );
                    clearInterval(getField);
                  }
                }, 100);
              }
            }
          }
        });
        // Define the target element to observe
        const targetElement = document.documentElement; // Assuming you want to observe the <html> element

        // Configuration options for the observer
        const config = {
          attributes: true, // Watch for attribute changes
          attributeFilter: ["lang"], // Only observe "lang" attribute changes
        };

        // Start observing the target element
        observer.observe(targetElement, config);
      };

      const getField = setInterval(() => {
        if ($(el.labelNumberOfPerson).length > 0) {
          $(el.inputDateCheckIn).prepend("<label>Incheckdatum</label>");
          $(el.inputDateCheckOut).prepend("<label>Uitcheckdatum</label>");
          listeningHTMLChange();
          clearInterval(getField);
        }
      }, 100);
      listeningHTMLChange();
    });
  };

  // const datePicker = () => {
  //   $(() => {
  //     const el = {
  //       page: ".single-vakantiewoningen",
  //       checkin: ".mi-Booking .dopbsp-row1 #DOPBSPCalendar-check-in-view1",
  //       checkout: ".mi-Booking .dopbsp-row1 #DOPBSPCalendar-check-out-view1",
  //     };

  //     if ($(el.page).length === 0) return;

  //     // const getField = setInterval(() => {
  //     //   if ($(el.checkin).length > 0) {
  //     //     // eslint-disable-next-line no-console
  //     //     console.log($(el.checkin));

  //     //     clearInterval(getField)
  //     //   }
  //     // }, 100)
  //     const convertDateString = (dateString) => {
  //       // Map month names to their corresponding numeric values
  //       var monthMap = {
  //         January: 0,
  //         February: 1,
  //         March: 2,
  //         April: 3,
  //         May: 4,
  //         June: 5,
  //         July: 6,
  //         August: 7,
  //         September: 8,
  //         October: 9,
  //         November: 10,
  //         December: 11,
  //       };

  //       // Split the date string into its components
  //       var dateParts = dateString.split(" ");

  //       // Extract the day, month, and year values
  //       var day = parseInt(dateParts[0]);
  //       var month = monthMap[dateParts[1]];
  //       var year = parseInt(dateParts[2]);

  //       // Create the Date object
  //       var dateObject = new Date(year, month, day);

  //       return dateObject;
  //     };

  //     $("body").on("change", el.checkin, () => {
  //       // Usage example
  //       var dateString = "8 June 2023"; // Assuming the format is 'D Month YYYY'
  //       var dateObject = convertDateString(dateString);

  //       // eslint-disable-next-line no-console
  //       console.log(dateObject);
  //     });
  //   });
  // };
  init();
};

try {
  booking();
} catch (e) {
  console.error("booking : ", e);
}
