import $ from 'jquery';

const filterCategories = () => {
  const init = () => {
    filter();
  }

  const filter = () => {
    const el = {
      button: '.js-FilterCategories',
      card: '.mi-Activities__ActivitiesPosts__Card',
    }

    if ($(el.card).length === 0) return;

    // remove all state class
    const initState = () => {
      $(el.card).removeClass('hide')
      $(el.button).removeClass('active')
    }

    // handle filter on click
    $(el.button).on("click", function () {
      initState()

      const category = $(this).data("category")
      $(this).addClass('active')

      if ($(el.card).not("." + category)) {
        $(el.card).not("." + category).addClass('hide')

        if (category === 'alle') {
          initState()
        }
      }
    })

  }

  init()
}


try {
  filterCategories();
} catch (e) {
  console.error('filter Categories : ', e);
}